import type { FC, PropsWithChildren } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

export const Anchor: FC<PropsWithChildren<{ href: string }>> = ({ href, children }) => {
  const { locale } = useRouter()

  return (
    <Link href={href} locale={locale}>
      <a>{children}</a>
    </Link>
  )
}

export default Anchor
