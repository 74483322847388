import type { FC } from 'react'
import { CwsH2 } from '@components/cws'
import { ButtonArrow } from '@ui/icons/button'
import { ProductSlider } from '@components/product'
import type { Product } from '@model/product'
import s from './Collection.module.scss'
import type { CollectionType } from '@api/categories'
import { Anchor } from '@components/common/Anchor'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { useBreadCrumbsState } from '@components/BreadCrumbs/State/state'
import { useInitialData } from '@hooks/useInitialData'
import { useEffect } from 'react'
import { useTranslate } from '@hooks/useTranslation'

export interface CollectionProps {
  products: Product[]
  name: CollectionType
  title: string
  text?: string
  hideArrow?: boolean
  hideTitle?: boolean
  listName: string
}

const Collection: FC<CollectionProps> = ({ products, name, title, text, listName, hideArrow, hideTitle = false }) => {
  const {
    query: { catalogId = '' },
    locale,
  } = useRouter()
  const { translate } = useTranslate()
  const { dispatch } = useBreadCrumbsState()
  const { catalogs } = useInitialData()
  useEffect(() => {
    const currentCatalog = catalogs.find((item) => item.id === catalogId)
    if (currentCatalog) {
      dispatch({
        type: 'setBreadcrumbs',
        payload: [
          { href: '/', title: translate('breadcrumb.home') },
          { href: `/${locale}/${currentCatalog.id}`, title: currentCatalog.title },
        ],
      })
    }
  }, [catalogId, locale, catalogs])
  const href = catalogId ? `/${catalogId}/collection/${name}` : `/collection/${name}`

  return (
    <div className={clsx(s.container, 'cws-pt-22')}>
      {hideTitle ? null : (
        <CwsH2 className="self-center">{hideArrow ? <span>{title}</span> : <Anchor href={href}>{title}</Anchor>}</CwsH2>
      )}
      {text && <CwsH2 className="self-center laptop:max-w-collection-text">{text}</CwsH2>}
      <ProductSlider listName={listName} mainPageCollection products={products} />
    </div>
  )
}

export default Collection
