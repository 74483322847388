import type { GetServerSideProps, NextPage } from 'next'
import { CwsGrid, CwsGridCol, CwsGridRow, CwsSection } from '@components/cws'
import { Helmet } from '@components/helmet'
import { TopBannerSlider } from '@components/home/TopBannerSlider'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { getContentByContentTypeId, getContentBySearchKey, konakartMethodCall } from '@api/proxy'
import { always, compose, map, propOr, tryCatch } from 'ramda'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { useTranslate } from '@hooks/useTranslation'
import type { CatalogFromContent, ImagePageBanner, WelcomeNote } from '@api/content'
import { getCatalogs, getWelcomeNoteContent, STATIC_CONTENT_TYPES } from '@api/content'
import axios from 'axios'
import type { KKContent } from '@model/content/content'
import { MOBILE_BP } from '@app/constants'
import { ProductsCollection } from '@components/common/ProductsCollection/ProductsCollection'
import { ShipSelection } from '@components/ShipSelection'
import WelcomeOnBoardNote from '@components/WelcomeOnBoardNote'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'

export type Banner = {
  searchKey?: string
  contentId: number
  zipFile: string
  clickUrl: string
  enabled: boolean
  custom3: string
}

interface HomePageProps {
  collections: string[]
  iframeBanners: string[]
  imageBanners: Omit<ImagePageBanner, 'title'>[]
  mobileBanners: Omit<ImagePageBanner, 'title'>[]
  welcomeNoteContent?: WelcomeNote | null
  catalogs: CatalogFromContent[]
  isPricelist: boolean
  errorCode?: string
}

const HomePage: NextPage<HomePageProps> = ({
  collections,
  iframeBanners = [],
  imageBanners,
  mobileBanners,
  isPricelist,
  errorCode,
  welcomeNoteContent,
  catalogs,
}) => {
  const { translate } = useTranslate()

  const title = translate(isGloballyPricelist() ? 'w2.pric.title.main' : 'w2.shop.title.main')
  const isMobile = useMediaLess(MOBILE_BP)
  const isWebshop = !isPricelist

  if (errorCode)
    return (
      <h1 className="w-screen flex justify-center text-2xl text-rose-600">
        <section>
          <p>
            ⛔️ Here is what happened: <code>{errorCode}</code>, you are probably not connected to the data source
          </p>
        </section>
      </h1>
    )

  return (
    <CwsSection className="cws-pt-xs">
      <Helmet type="website" title={title} />
      {/*<section className="flex flex-col justify-center container max-w-4.5xl mx-auto mb-10">*/}
      <CwsGrid>
        <CwsGridRow>
          <CwsGridCol col="12">
            {!isMobile && <TopBannerSlider imageBanners={imageBanners} />}
            {isMobile && <TopBannerSlider imageBanners={mobileBanners} />}
            {isWebshop && <ProductsCollection collections={collections} />}
            {isPricelist && welcomeNoteContent && <WelcomeOnBoardNote content={welcomeNoteContent} />}
            {isPricelist && <ShipSelection catalogs={catalogs} />}
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
      {/*</section>*/}
    </CwsSection>
  )
}

export default HomePage

export const transformContents = compose(
  map(({ description: { contentId, name1 }, clickUrl, enabled, custom3, searchKey }) => ({
    contentId,
    clickUrl: clickUrl ?? '',
    zipFile: name1,
    enabled,
    custom3,
    searchKey,
  })),
  propOr<KKContent[]>([], 'contents')
)

export const getServerSideProps: GetServerSideProps = async ({ res, req, locale }) => {
  let catalogs: CatalogFromContent[] = []

  try {
    const initCollections = await konakartMethodCall<string>({
      f: 'getConfigurationValue',
      key: 'DFSHOP_WELCOME_COMPONENTS',
    })

    const storeId = process.env.KK_STORE || 'store2'
    let welcomeNoteContent: WelcomeNote | null = null

    const iframeBanners: string[] = []
    let imageBanners: Omit<ImagePageBanner, 'title'>[] = []
    let mobileBanners: Omit<ImagePageBanner, 'title'>[] = []

    let mainBannersResponse: unknown

    if (req && res) {
      res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')

      if (storeId === 'store1') {
        welcomeNoteContent = (await getWelcomeNoteContent(KK_LANG_IDS[locale as Locale])) || null
        catalogs = (await getCatalogs(KK_LANG_IDS[locale as Locale])) || []
        mainBannersResponse = await getContentBySearchKey('Welcome Banner', locale as Locale)
      } else {
        mainBannersResponse = await getContentByContentTypeId(STATIC_CONTENT_TYPES['TOP_BANNER'], locale as Locale)
      }

      // const contentHtmlBannersResponse = await getContentBySearchKey('html-banner', locale as Locale)
      // const contentImageBannersResponse = await getContentBySearchKey('image-banner', locale as Locale)
      // const mobileBannersResponse = await getContentBySearchKey('mobile-banner', locale as Locale)

      // const banners = transformContents(contentHtmlBannersResponse) as Banner[]
      // for (const banner of banners) {
      //   const bannerPath = await downloadBannerByName(
      //     `banner-${banner.contentId}`,
      //     `${banner.zipFile}`
      //   )
      //
      //   const [, publicPath] = bannerPath.split('/public')
      //   iframeBanners.push(publicPath)
      // }
      //todo: move the download logic to the build script

      const iBanners = transformContents(mainBannersResponse) as Banner[]
      imageBanners = iBanners
        .filter(({ enabled }) => enabled)
        .sort((c1, c2) => (c1.custom3 || '').localeCompare(c2.custom3 || ''))
        .map(({ zipFile, clickUrl, custom3 }) => {
          return {
            image: zipFile,
            clickUrl: clickUrl.startsWith('https://') ? clickUrl : '',
          }
        })

      const xsBanners = transformContents(mainBannersResponse) as Banner[]
      mobileBanners = xsBanners
        .filter(({ enabled }) => enabled)
        .sort((c1, c2) => (c1.custom3 || '').localeCompare(c2.custom3 || ''))
        .map(({ zipFile, clickUrl, custom3 }) => {
          return {
            image: zipFile,
            clickUrl: clickUrl.startsWith('https://') ? clickUrl : '',
          }
        })
    }

    const parsedCollectionsData = compose(
      propOr([], storeId),
      tryCatch((json: string) => JSON.parse(json), always({}))
    )(initCollections)

    return {
      props: {
        collections: parsedCollectionsData,
        iframeBanners,
        imageBanners,
        mobileBanners,
        isPricelist: storeId === 'store1',
        welcomeNoteContent,
        catalogs,
      },
    }
  } catch (e) {
    let errorCode = ''
    if (axios.isAxiosError(e)) {
      errorCode = e.code ?? ''
    }

    return {
      props: {
        collections: [],
        iframeBanners: [],
        imageBanners: [],
        mobileBanners: [],
        errorCode,
        isPricelist: false,
        welcomeNoteContent: null,
        catalogs,
      },
    }
  }
}
